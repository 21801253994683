import React, { useRef } from "react";
import { useIntl } from "gatsby-plugin-intl";

import { ProjectImage } from "./ProjectImage";
import { ProjectDataType } from "../../hooks/useGetProjects";
import Tech from "./Tech";
import { AnimatedLink } from "../Nav/AnimatedLink";

type ProjectProps = {
  project: ProjectDataType["frontmatter"];
};

const Project: React.FC<ProjectProps> = ({ project }) => {
  const projectRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  return (
    <div className="project__wrapper" ref={projectRef}>
      <AnimatedLink
        to={`/project/${project.slug}`}
        className="project__overlay"
        aria-label={`project ${project.title} link`}
      />
      <div className="project__img">
        <ProjectImage
          image={project.image.childImageSharp}
          alt={project.title}
        />
      </div>
      <div className="project__info">
        <h4 className="project__title">{project.title}</h4>
        <p className="project__description">{project.description}</p>
        <div className="project__tech-wrapper">
          {project.tech.map((tech: string, index: number) => (
            <Tech key={index} tech={tech} />
          ))}
        </div>
        {(project.appLink || project.githubLink) && (
          <div className="project__links-wrapper">
            {project.githubLink && (
              <a
                href={project.githubLink}
                target="_blank"
                aria-label="Link to github page of the project"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                  className="project__links"
                >
                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                </svg>
              </a>
            )}
            {project.appLink && (
              <a
                href={project.appLink}
                target="_blank"
                aria-label="Link to project"
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                  className="project__links"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Project;
