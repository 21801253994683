import { IntlShape } from "gatsby-plugin-intl";
import React from "react";

type SkillsWrapperProps = {
  intl: IntlShape;
  title: string;
};

const SkillsWraper: React.FC<SkillsWrapperProps> = ({
  children,
  intl,
  title,
}) => {
  return (
    <div className="skills">
      <h3 className="skills__title">{intl.formatMessage({ id: title })}</h3>
      <div className="skills__wrapper">{children}</div>
    </div>
  );
};

export default SkillsWraper;
