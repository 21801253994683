import { graphql, useStaticQuery } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";

type ProjectsDataType = {
  apps: {
    nodes: ProjectDataType[];
  };
  websites: {
    nodes: ProjectDataType[];
  };
};

export type ProjectDataType = {
  id: string;
  frontmatter: {
    title: string;
    type: string;
    slug: string;
    description: string;
    tech: string[];
    githubLink: string;
    appLink: string;
    image: {
      childImageSharp: ImageDataLike;
    };
  };
};

export const useGetProjects = () => {
  const data: ProjectsDataType = useStaticQuery(PROJECTS_QUERY);

  return [data.apps.nodes, data.websites.nodes];
};

const PROJECTS_QUERY = graphql`
  query GetProjects {
    apps: allMdx(
      filter: { frontmatter: { type: { eq: "app" } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          tech
          description
          githubLink
          appLink
        }
      }
    }
    websites: allMdx(
      filter: { frontmatter: { type: { eq: "website" } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          tech
          description
          githubLink
          appLink
        }
      }
    }
  }
`;
