import React, { useRef } from "react";
import { IntlShape, useIntl } from "gatsby-plugin-intl";

import Seo from "../components/Seo";
import Header from "../components/Header";
import SkillsSection from "../components/SkillsSection";
import ProjectsSection from "../components/ProjectsSection";
import ContactSection from "../components/ContactSection";
import Layout from "../components/Layout";

const IndexPage = () => {
  const intl: IntlShape = useIntl();
  const skillsRef = useRef<HTMLElement>(null);
  const projectsRef = useRef<HTMLElement>(null);
  const contactRef = useRef<HTMLElement>(null);

  return (
    <>
      <Seo title="Kubahrom" />
      <Layout
        skillsRef={skillsRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
        intl={intl}
      >
        <Header intl={intl} projectsRef={projectsRef} contactRef={contactRef} />
        <ProjectsSection intl={intl} projectsRef={projectsRef} />
        <SkillsSection intl={intl} skillsRef={skillsRef} />
        <ContactSection intl={intl} contactRef={contactRef} />
      </Layout>
    </>
  );
};

export default IndexPage;
