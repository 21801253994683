import { IntlShape } from "gatsby-plugin-intl";
import React from "react";

import { ProjectDataType, useGetProjects } from "../hooks/useGetProjects";
import Project from "./Projects/Project";
import Title from "./Shared/Title";

type ProjectsSectionProps = {
  intl: IntlShape;
  projectsRef: React.RefObject<HTMLElement>;
};

const ProjectsSection: React.FC<ProjectsSectionProps> = ({
  intl,
  projectsRef,
}) => {
  const [apps, websites] = useGetProjects();

  return (
    <section ref={projectsRef} className="projects" id="projects-section">
      <Title>{intl.formatMessage({ id: "projects-title" })}</Title>
      <p className="projects__info">
        {intl.formatMessage({ id: "projects-info" })}
      </p>
      <div className="projects__section">
        <h3 className="projects__title">
          {intl.formatMessage({ id: "projects-apps" })}
        </h3>
        <div className="projects__wrapper">
          {apps.map((project: ProjectDataType) => (
            <Project key={project.id} project={project.frontmatter} />
          ))}
        </div>
      </div>
      <div className="projects__section">
        <h3 className="projects__title">
          {intl.formatMessage({ id: "projects-websites" })}
        </h3>
        <div className="projects__wrapper">
          {websites.map((project: ProjectDataType) => (
            <Project key={project.id} project={project.frontmatter} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
