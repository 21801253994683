import { IntlShape } from "gatsby-plugin-intl";
import React from "react";
import {
  lookingForwardSkills,
  skillsData,
  SkillSectionType,
  SkillType,
} from "../data/skills";
import Title from "./Shared/Title";
import Skill from "./Skills/Skill";
import SkillsWraper from "./Skills/SkillsWraper";

type SkillsSectionProps = {
  intl: IntlShape;
  skillsRef: React.RefObject<HTMLElement>;
};

const SkillsSection: React.FC<SkillsSectionProps> = ({ intl, skillsRef }) => {
  return (
    <section ref={skillsRef} className="skills__section" id="skills-section">
      <Title>{intl.formatMessage({ id: "nav-skills" })}</Title>
      <p className="skills__info">
        {intl.formatMessage({ id: "skills-info" })}
      </p>
      {skillsData.map((skillSection: SkillSectionType) => (
        <SkillsWraper
          key={skillSection.id}
          intl={intl}
          title={skillSection.name}
        >
          {skillSection.data.map((skill: SkillType, index) => (
            <Skill key={index} name={skill.name} logo={skill.logo} />
          ))}
        </SkillsWraper>
      ))}
      <div className="skills__favourite-wrapper">
        <p className="skills__favourite">
          {intl.formatMessage({ id: "skills-favourite" })}
        </p>
        <div className="skills__tech-wrapper">
          <span className="project__tech">React</span>
          <span className="project__tech">Next.js</span>
          <span className="project__tech">Typescript</span>
          <span className="project__tech">Styled-components</span>
          <span className="project__tech">Framer-motion</span>
        </div>
      </div>
      <SkillsWraper intl={intl} title={lookingForwardSkills.name}>
        {lookingForwardSkills.data.map((skill: SkillType, index) => (
          <Skill key={index} name={skill.name} logo={skill.logo} />
        ))}
      </SkillsWraper>
    </section>
  );
};

export default SkillsSection;
