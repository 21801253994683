import React from 'react';
import SVG from 'react-inlinesvg';

type SkillProps = {
  name: string;
  logo: any;
};

const Skill: React.FC<SkillProps> = ({ name, logo }) => {
  return (
    <div className="skill">
      <SVG src={logo} className="skill__logo" />
      <span className="skill__name">{name}</span>
    </div>
  );
};

export default Skill;
