import { IntlShape } from "gatsby-plugin-intl";
import gsap from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import React, { useEffect } from "react";
import { animationIsOkay } from "../utils/utilFunctions";
import HeaderImage from "./Header/HeaderImage";
import HeaderLinks from "./Header/HeaderLinks";

type HeaderProps = {
  intl: IntlShape;
  projectsRef: React.RefObject<HTMLElement>;
  contactRef: React.RefObject<HTMLElement>;
};

const Header: React.FC<HeaderProps> = ({ intl, projectsRef, contactRef }) => {
  gsap.registerPlugin(CSSRulePlugin);
  const tl = gsap.timeline({
    defaults: {
      ease: "expo.easyInOut",
    },
  });

  const getMediaQueryOffset = () => {
    if (window.matchMedia("(max-width: 900px)").matches) return 60;
    return 0;
  };

  const handleProjects = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (projectsRef.current) {
      window.scrollTo(0, projectsRef.current.offsetTop - getMediaQueryOffset());
      //@ts-ignore
      e.target.blur();
    }
  };

  const handleContact = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (contactRef.current) {
      window.scrollTo(0, contactRef.current.offsetTop);
      // @ts-ignore
      e.target.blur();
    }
  };

  useEffect(() => {
    if (!animationIsOkay()) return;

    if (!window.matchMedia("(min-width: 768px)").matches) return;

    const backgroundEl = document.querySelector(
      ".header__background"
    ) as Element;
    const sizeInViewport = backgroundEl.getBoundingClientRect().right;

    tl.from(".header--hidetext", {
      y: "100%",
      delay: 1.1,
      stagger: 0.03,
    })
      .from(
        ".header__info",
        {
          opacity: 0,
          stagger: 0.08,
          x: -20,
        },
        "-=0.05"
      )
      .from(
        ".btn",
        {
          opacity: 0,
          stagger: 0.15,
          duration: 0.1,
          x: -10,
        },
        "-=0.3"
      )
      .from(".header__link--hidetext", {
        opacity: 0,
        stagger: 0.2,
        duration: 0.3,
        x: -10,
        scale: 0,
      })
      .from(
        ".header__image-wrapper",
        {
          opacity: 0,
          xPercent: 20,
          duration: 0.3,
        },
        "-=0.8"
      )
      .from(
        CSSRulePlugin.getRule(".header__background::before"),
        {
          cssRule: {
            x: -sizeInViewport,
          },
          duration: 0.25,
          ease: "easeOut",
        },
        "-=0.3"
      );
  }, []);

  return (
    <header className="header">
      <div className="header__container">
        <HeaderImage />
        <div className="header__wrapper">
          <div className="header__background">
            <p className="header__welcome">
              <span className="header--hidetext">
                {intl.formatMessage({ id: "header-welcome1" })}
                <span className="header__wave">👋</span>
                {intl.formatMessage({ id: "header-welcome2" })}
              </span>
            </p>
            <p className="header__name">
              <span className="header--hidetext">
                {intl.formatMessage({ id: "header-name" })}
              </span>
            </p>
            <h1 className="header__intro">
              <span className="header--hidetext">
                {intl.formatMessage({ id: "header-intro" })}
              </span>
            </h1>
          </div>
          <p
            className="header__info"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "header-info1" }),
            }}
          />
          <p
            className="header__info"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "header-info2" }),
            }}
          />
          <p
            className="header__info"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "header-info3" }),
            }}
          />
          <div className="btn__wrapper">
            <button className="btn btn__submit" onClick={handleProjects}>
              {intl.formatMessage({ id: "header-projects" })}
            </button>
            <button className="btn" onClick={handleContact}>
              {intl.formatMessage({ id: "header-email" })}
            </button>
          </div>
          <HeaderLinks />
        </div>
      </div>
    </header>
  );
};

export default Header;
